import React from 'react';
import './lobby-item.css';
import * as request from 'superagent';
import { bus, env } from './../../helpers';
import { AnimText } from '../anim_text/anim_text';
import { ICurrencyDTO } from '../../interfaces';


export const LobbyItem = (props: { modelId: string; platform: string; currency: string; hasJackpot?: boolean; link: string, info?: React.ReactNode[], importantInfo?: React.ReactNode[] }) => {
  const [jackpot, setJackpot] = React.useState(0);
  const [currency, setCurrency] = React.useState<ICurrencyDTO | undefined>(undefined);
  const [busy, setBusy] = React.useState(false);
  const [showInfo, setShowInfo] = React.useState(false);
  const [showLinkOpen, setShowLinkOpen] = React.useState(false);
  const onShow = (b: boolean) => {
    setShowInfo(b);
    bus.emit('close-all-lobby-icons', props.modelId);
  }
  React.useEffect(() => {
    if (showInfo) {
      setTimeout(() => setShowLinkOpen(true), 100);
      return;
    }
    setShowLinkOpen(false);
  }, [showInfo]);

  React.useEffect(() => {
    const closeAllLobbyIcons = (model: string) => {
      if (props.modelId === model) { return; }
      setShowInfo(false);
    }
    bus.on('close-all-lobby-icons', closeAllLobbyIcons);
    if (!props.hasJackpot) { return; }
    const call = async () => {
      if (busy) { return; }
      setBusy(true);
      try {
        const res = (await request.post(`${env.api}/n/slots/poll/jackpot`).send({modelId: props.modelId, platform: props.platform, currency: props.currency}))?.body;
        const currency = res.currencyDTO.find((it: any) => it.name==='SW');
        const grand = res.jackpots.grand;
        setCurrency(currency);
        setJackpot(grand.amount);
      } catch (e) {
        console.error(e);
      } finally {
        setBusy(false);
      }
    }
    call();
    const interval = setInterval(() => {
      call();
    }, 12_000);
    return () => {
      bus.off('close-all-lobby-icons', closeAllLobbyIcons);
      clearInterval(interval); 
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return !currency ? null : 
  <div className={`lobby-item`} style={{backgroundImage: `url('images/lobby-items/${props.modelId}.png')`}}>
    {/* it's an 'a' so that we can do open in new link */}
    <div className={`lobby-item-jackpot`}>
      <AnimText 
        currency={currency}
        startFromZero={false}
        snapIfValueIsLower={true}
        text={``}
        initialNumber={jackpot * 0.9995}
        tweenTime={12_000}
        isCurrency={true}
        convertCredits={true}
        finalNumber={jackpot}
        easing={'linear'}
      />
    </div>
    <div className={`lobby-item-info ${showInfo ? 'in' : 'out'}`}>
      <div className={'ol'}></div>
      <div className={'content'}>
        { props.importantInfo?.map((it, i) => <span key={`${props.modelId}-imp-${i}`}>{it}</span>) }
        { props.info?.map((it, i) => <span key={`${props.modelId}-info-${i}`}>{it}</span>) }
        <div className={`launch-game`}>Launch 🚀</div>
      </div>
    </div>
    {showInfo 
      ? <>{ showLinkOpen && <a href={`${props.link}`} onMouseLeave={() => onShow(false)}></a> }</>
      : <a href={'#'} onClick={(e) => e?.preventDefault()} onMouseEnter={() => onShow(true)} onTouchStart={() => onShow(true)} ></a>
    }
  </div>
}