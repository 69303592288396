import { ICurrencyDTO } from './../../interfaces';
import { convertCreditsToCurrency, formatCurrency } from './../../helpers';
import React from 'react';
interface IProps {
  credits: number; currency?: ICurrencyDTO; convertCredits?: boolean; initialText?: string;
}
interface IState {
  num: string; symbol: string;
}
export class CurrencyText extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { num: this.props.initialText ?? '', symbol: `${props.currency?.symbol || ''}`}
  }
  public componentDidMount(): void {
    if (typeof this.props.initialText === 'undefined') {
      this.format(this.props.credits);
    }
  }
  public componentDidUpdate(prevProps: IProps) {
    if (prevProps.credits !== this.props.credits || this.props.currency !== prevProps.currency) {
      this.format(this.props.credits);
    }
  }
  public format(n: number) {
    if (!this.props.currency) { return this.setState({ num: `${n}` }); }
    const value = this.props.convertCredits ? convertCreditsToCurrency(n, this.props.currency) : n;
    const num = formatCurrency(value, this.props.currency);
    const symbol = `${this.props.currency?.symbol || ''}`;
    this.setState({ num, symbol });
  }
  public render() {
    return <>{this.state.num}</>;
  }
}
