import { ICurrencyDTO } from "../interfaces";
import numeral from 'numeral';
import EventEmitter from 'eventemitter3';

export const env = {
  api: `${process.env.REACT_APP_API_URL}`,
  cdn: `${process.env.REACT_APP_API_CDN}`,
}

export function isNumeric(str: any) {
  if (str == null) {
    return false;
  }
  const regx = /^[-+]?\d*\.?\d+(?:[eE][-+]?\d+)?$/;
  return regx.test(str);
}

// this format comes from currencyHelpers.getCurrencyToUser
export const convertCreditsToCurrency = (credits: number, currency: ICurrencyDTO) => (credits * currency.creditRate) / (1 * Math.pow(10, currency.maxDecimals));
export const formatCurrency = (n: number, currency: ICurrencyDTO) => `${currency.symbol ? currency.symbol + " " : ''}${numeral(Number(n)).format(currency.currencyFormat || '0,000.00')}`;

export const bus = new EventEmitter();