import gsap from 'gsap';
import {Linear, Cubic, Quad, Elastic, Back, Power0, Power1, Power2, Power3, Power4 } from 'gsap';

import { ITween } from '../../interfaces';
export type TEase =     
  'linear'
  | 'cubic.easein'    | 'cubic.easeout'   | 'cubic.easeinout'
  | 'quad.easein'     | 'quad.easeout'    | 'quad.easeinout'
  | 'elastic.easein'  | 'elastic.easeout' | 'elastic.easeinout'
  | 'back.easein'     | 'back.easeout'    | 'back.easeinout'
  | 'power0.easein'   | 'power0.easeout'  | 'power0.easeinout'
  | 'power1.easein'   | 'power1.easeout'  | 'power1.easeinout'
  | 'power2.easein'   | 'power2.easeout'  | 'power2.easeinout'
  | 'power3.easein'   | 'power3.easeout'  | 'power3.easeinout'
  | 'power4.easein'   | 'power4.easeout'  | 'power4.easeinout';

interface ITweenProps {
  target: any;
  props: any;
  duration: number; // in Milliseconds
  delay?: number; // in Milliseconds
  yoyo?: boolean;
  repeat?: number;
  onUpdate?: () => void;
  onComplete?: () => void;
  ease?: TEase;
}
export function tween(params: ITweenProps): ITween {
  const tw = gsap.to(params.target, 
    { 
      ...params.props, 
      duration: params.duration / 1_000,
      delay: (params.delay || 0) / 1_000,
      yoyo: params.yoyo,
      repeat: params.repeat === undefined ? 0 : params.repeat,
      ease: getRealEasing(params.ease),
      onUpdate: params.onUpdate,
      onComplete: params.onComplete,
    });
  return {
    ...tw,
    stop: () => tw.kill(),
  };
}

export function tweenPromise(params: ITweenProps): Promise<void> {
  return new Promise<void>(resolve => {
    tween({
      ...params,
      onComplete: () => {
        params.onComplete?.();
        resolve();
      },
    });
  });
}

export function killTweens(tweens: ITween[]) {
  tweens.forEach((t) => {
    try {
      t?.stop?.();
    } catch (e) {
      console.error(e);
    }
  });
  return [];
}
const getRealEasing = (ease?: TEase) => {
  const e = ease?.toLowerCase() || 'linear';
  switch (e) {
    case 'cubic.easein':      return Cubic.easeIn;
    case 'cubic.easeout':     return Cubic.easeOut;
    case 'cubic.easeinout':   return Cubic.easeInOut;
    case 'quad.easein':       return Quad.easeIn;
    case 'quad.easeout':      return Quad.easeOut;
    case 'quad.easeinout':    return Quad.easeInOut;
    case 'elastic.easein':    return Elastic.easeIn;
    case 'elastic.easeout':   return Elastic.easeOut;
    case 'elastic.easeinout': return Elastic.easeInOut;
    case 'back.easein':       return Back.easeIn;
    case 'back.easeout':      return Back.easeOut;
    case 'back.easeinout':    return Back.easeInOut;
    case 'power0.easein':     return Power0.easeIn;
    case 'power0.easeout':    return Power0.easeOut;
    case 'power0.easeinout':  return Power0.easeInOut;
    case 'power1.easein':     return Power1.easeIn;
    case 'power1.easeout':    return Power1.easeOut;
    case 'power1.easeinout':  return Power1.easeInOut;
    case 'power2.easein':     return Power2.easeIn;
    case 'power2.easeout':    return Power2.easeOut;
    case 'power3.easeout':    return Power3.easeOut;
    case 'power3.easeinout':  return Power3.easeInOut;
    case 'power4.easein':     return Power4.easeIn;
    case 'power4.easeout':    return Power4.easeOut;
    case 'power4.easeinout':  return Power4.easeInOut;
    default: return Linear;
  }
};
