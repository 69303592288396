import React from 'react';
import './landing.css';
import './games.css'
import { Link } from 'react-router-dom';
import { LobbyItem } from './components/lobby-item/lobby-item';
const infoGames = [
  { 
    modelId: 'ff010-breaking-ways',
    hasJackpot: true,
    platform: 'COM',
    currency: 'SW',
    link: 'https://games.efef.fun/ff010-breaking-ways/index.html',
    importantInfo: [
      <div className={`lobby-item-important-info`}>
        <div className={`lobby-item-reelsetup`}>(5x3)+</div>
        <div className={`lobby-item-type`}>243+ ways</div>
        <div className={`lobby-item-rtp`}>95.6% rtp</div>
      </div>
    ],
  },
  { 
    modelId: 'ff011-breaking-buffalo',
    hasJackpot: true,
    platform: 'COM',
    currency: 'SW',
    link: 'https://games.efef.fun/ff011-breaking-buffalo/index.html',
    importantInfo: [
      <div className={`lobby-item-important-info`}>
        <div className={`lobby-item-reelsetup`}>(5x3)+</div>
        <div className={`lobby-item-type`}>243+ ways</div>
        <div className={`lobby-item-rtp`}>95.6% rtp</div>
      </div>
    ],
  },
  { 
    modelId: 'ff008-egypt',
    hasJackpot: true,
    platform: 'COM',
    currency: 'SW',
    link: 'https://games.efef.fun/ff008-egypt/index.html',
    importantInfo: [
      <div className={`lobby-item-important-info`}>
        <div className={`lobby-item-reelsetup`}>6x4</div>
        <div className={`lobby-item-type`}>4096 ways</div>
        <div className={`lobby-item-rtp`}>95.9% rtp</div>
      </div>
    ],
    info: [
      <div className={`lobby-item-normal-info`}>
        <div>Shifting Reels</div>
        <div>Coin-Spins Feature</div>
        <div>Free Spins Feature</div>
      </div>
    ],
  },
  { 
    modelId: 'ff001-starstruck',
    hasJackpot: true,
    platform: 'COM',
    currency: 'SW',
    link: `https://games.efef.fun/ff001-starstruck/index.html`,
    importantInfo: [
      <div className={`lobby-item-important-info`}>
        <div className={`lobby-item-reelsetup`}>5x4</div>
        <div className={`lobby-item-type`}>1024 ways</div>
        <div className={`lobby-item-rtp`}>95.9% rtp</div>
      </div>
    ],
    info: [
      <div className={`lobby-item-normal-info`}>
        <div>Powerup Feature</div>
        <div>Coin-Spins Feature</div>
        <div>Free Spins Feature</div>
      </div>
    ],
  },
  { 
    modelId: 'ff001-starstruck-lines',
    hasJackpot: true,
    platform: 'COM',
    currency: 'SW',
    link: 'https://games.efef.fun/ff001-starstruck-lines/index.html',
    importantInfo: [
      <div className={`lobby-item-important-info`}>
        <div className={`lobby-item-reelsetup`}>5x4</div>
        <div className={`lobby-item-type`}>50 Lines</div>
        <div className={`lobby-item-rtp`}>95.9% rtp</div>
      </div>
    ],
    info: [
      <div className={`lobby-item-normal-info`}>
        <div>Powerup Feature</div>
        <div>Coin-Spins Feature</div>
        <div>Free Spins Feature</div>
      </div>
    ],
  },
  { 
    modelId: 'ff002-carnival',
    hasJackpot: true,
    platform: 'COM',
    currency: 'SW',
    link: 'https://games.efef.fun/ff002-carnival/index.html',
    importantInfo: [
      <div className={`lobby-item-important-info`}>
        <div className={`lobby-item-reelsetup`}>6x3</div>
        <div className={`lobby-item-type`}>729 ways</div>
        <div className={`lobby-item-rtp`}>96.2% rtp</div>
      </div>
    ],
    info: [
      <div className={`lobby-item-normal-info`}>
        <div>Expanding Wilds</div>
        <div>Coin-Spins Feature</div>
        <div>Free Spins Feature</div>
      </div>
    ],
  },
  { 
    modelId: 'ff003-viking',
    hasJackpot: true,
    platform: 'COM',
    currency: 'SW',
    link: 'https://games.efef.fun/ff003-viking/index.html',
    importantInfo: [
      <div className={`lobby-item-important-info`}>
        <div className={`lobby-item-reelsetup`}>6x4</div>
        <div className={`lobby-item-type`}>4096 ways</div>
        <div className={`lobby-item-rtp`}>95.9% rtp</div>
      </div>
    ],
    info: [
      <div className={`lobby-item-normal-info`}>
        <div>Coin-Spins Feature</div>
        <div>Free Spins Feature</div>
      </div>
    ],
  },
  { 
    modelId: 'ff004-china',
    hasJackpot: true,
    platform: 'COM',
    currency: 'SW',
    link: 'https://games.efef.fun/ff004-china/index.html',
    importantInfo: [
      <div className={`lobby-item-important-info`}>
        <div className={`lobby-item-reelsetup`}>6x4</div>
        <div className={`lobby-item-type`}>4096 ways</div>
        <div className={`lobby-item-rtp`}>95.9% rtp</div>
      </div>
    ],
    info: [
      <div className={`lobby-item-normal-info`}>
        <div>Coin-Spins Feature</div>
        <div>Free Spins Feature</div>
      </div>
    ],
  },
  { 
    modelId: 'ff005-mexico',
    hasJackpot: true,
    platform: 'COM',
    currency: 'SW',
    link: 'https://games.efef.fun/ff005-mexico/index.html',
    importantInfo: [
      <div className={`lobby-item-important-info`}>
        <div className={`lobby-item-reelsetup`}>5x3</div>
        <div className={`lobby-item-type`}>25 Lines</div>
        <div className={`lobby-item-rtp`}>96.1% rtp</div>
      </div>
    ],
    info: [
      <div className={`lobby-item-normal-info`}>
        <div>Coin-Spins Feature</div>
        <div>Free Spins Feature</div>
      </div>
    ],
  },
  { 
    modelId: 'ff006-retro-future',
    hasJackpot: true,
    platform: 'COM',
    currency: 'SW',
    link: 'https://games.efef.fun/ff006-retro-future/index.html',
    importantInfo: [
      <div className={`lobby-item-important-info`}>
        <div className={`lobby-item-reelsetup`}>6x4</div>
        <div className={`lobby-item-type`}>4096 ways</div>
        <div className={`lobby-item-rtp`}>95.9% rtp</div>
      </div>
    ],
    info: [
      <div className={`lobby-item-normal-info`}>
        <div>Coin-Spins Feature</div>
        <div>Free Spins Feature</div>
      </div>
    ],
  },
  { 
    modelId: 'ff007-greek',
    hasJackpot: true,
    platform: 'COM',
    currency: 'SW',
    link: 'https://games.efef.fun/ff007-greek/index.html',
    importantInfo: [
      <div className={`lobby-item-important-info`}>
        <div className={`lobby-item-reelsetup`}>6x4</div>
        <div className={`lobby-item-type`}>4096 ways</div>
        <div className={`lobby-item-rtp`}>95.9% rtp</div>
      </div>
    ],
    info: [
      <div className={`lobby-item-normal-info`}>
        <div>Coin-Spins Feature</div>
        <div>Free Spins Feature</div>
      </div>
    ],
  },
  { 
    modelId: 'ff009-mad-scientist',
    hasJackpot: true,
    platform: 'COM',
    currency: 'SW',
    link: 'https://games.efef.fun/ff009-mad-scientist/index.html',
    importantInfo: [
      <div className={`lobby-item-important-info`}>
        <div className={`lobby-item-reelsetup`}>5x3</div>
        <div className={`lobby-item-type`}>243 ways</div>
        <div className={`lobby-item-rtp`}>95.9% rtp</div>
      </div>
    ],
    info: [
      <div className={`lobby-item-normal-info`}>
        <div>Coin-Spins Feature</div>
        <div>Free Spins Feature</div>
      </div>
    ],
  },
];

const LandingPage = () => {
  const HomeRef = React.useRef<HTMLDivElement>(null);
  const GamesRef = React.useRef<HTMLDivElement>(null);
  // const JobsRef = React.useRef<HTMLDivElement>(null);
  const AboutUsRef = React.useRef<HTMLDivElement>(null);
  const ContactUsRef = React.useRef<HTMLDivElement>(null);

  const goTo = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, ref: React.RefObject<HTMLDivElement>) => {
    e.preventDefault();
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
  <div className={"landing-page"}>
  <header ref={HomeRef}>
    <div style={{ position: "absolute", right: 10, top: 5, height: "100%" }}>
      {/* <div className={"logo"}><span className={"icon-fezuone-logo-v"}><span className={"path1"}></span><span className={"path2"}></span><span className={"path3"}></span><span className={"path4"}></span></span></div> */}

    </div>
    <div className={"header-menu"}>
      <a href="." onClick={e => goTo(e, HomeRef)}>Home</a>
      <a href="." onClick={e => goTo(e, GamesRef)}>Games</a>
      <a href="." onClick={e => goTo(e, AboutUsRef)}>About us</a>
      {/* <a href="." onClick={e => goTo(e, ContactUsRef)}>Contact us</a> */}
    </div>
  </header>
  <section className={"promo-0"} style={{backgroundImage: `url('images/promo-6.png')`}}>
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <div style={{fontSize: 48, textShadow: `rgb(0, 0, 0) 5px 5px`}}><span className={"icon-fezuone-logo"}><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span><span className="path10"></span><span className="path11"></span></span></div>
      <div style={{textShadow: `rgb(0, 0, 0) 2px 2px`, pointerEvents: 'none', fontSize: 23, fontFamily: `"Madimi One", sans-serif`}}>Innovative slots games</div>
    </div>
  </section>
  <section className="about-section" ref={AboutUsRef}>
    <div className="content-container">
      <h1>About Us</h1>
      <p>We are a passionate team of game developers dedicated to creating immersive and unforgettable gaming experiences. With a perfect blend of creativity, technical expertise, and a deep understanding of player preferences, we strive to deliver games that captivate and engage players worldwide.</p>
    </div>
  </section>
  <section className="games-section" ref={GamesRef}>
    <div className="title-content-container"><h1>Slot Games</h1></div>
    <div className="content-container">
      {infoGames.map((it, i) =>
        <LobbyItem 
          key={`lobby-item-${i}`} 
          modelId={it.modelId} 
          platform={it.platform} 
          currency={it.currency!} 
          hasJackpot={it.hasJackpot} 
          link={it.link} 
          info={it.info} 
          importantInfo={it.importantInfo} 
        />
      )}
    </div>
  </section>
  <section className="expertise-section">
    <div className="content-container">
      <h2>Our Expertise</h2>
      <ul>
        <li>
          <h3>Ai</h3>
          <p>Our team leverages ai in their daily workflow, from Art, programming, music and content generation.</p>
        </li>
        <li>
          <h3>Game Design</h3>
          <p>Our talented designers work tirelessly to craft unique game concepts and engaging narratives that will keep players hooked from start to finish.</p>
        </li>
        <li>
          <h3>Development</h3>
          <p>Our experienced developers utilize cutting-edge technologies and industry best practices to bring our game designs to life.</p>
        </li>
        <li>
          <h3>Multi-platform</h3>
          <p>We understand the importance of reaching a wide audience, which is why we develop games for multiple platforms, including PC, and mobile.</p>
        </li>
        <li>
          <h3>Collaboration</h3>
          <p>We believe in the power of collaboration. We work closely with our clients to understand their vision and transform their ideas into exceptional games.</p>
        </li>
      </ul>
    </div>
  </section>
  {/* <section className="promo-0" style={{backgroundImage: `url('images/promo-1.png')`}}>
    <h3 style={{textShadow: `rgb(0, 0, 0) 2px 2px`, lineHeight: '1.8em', maxWidth: 700, padding: 32, textAlign: 'center'}}>Player satisfaction is at the core of everything we do. We prioritize player feedback and iterate on our games to ensure they deliver the best possible experience.</h3>
  </section> */}
  {/* <section className="jobs-section" ref={JobsRef}>
    <div className="content-container">
      <h1>Jobs</h1>
      <h3 style={{textShadow: `rgb(0, 0, 0) 2px 2px`, lineHeight: `1.8em`, maxWidth: 700, padding: 32, textAlign: 'center'}}>Contact us! we'd be happy to have you onboard!</h3>
    </div>
  </section> */}
  <section className="promo-0" style={{backgroundImage: `url('images/promo-3.png')`}}></section>
  <section className="contact-section" ref={ContactUsRef}>
    <div style={{fontSize: 72}}><span className="icon-fezuone-logo-v"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span></div>
    <div className="content-container">
      <div><a href="mailto:info@efef.fun">Contact Us</a></div>
      <div><Link to="tos/terms-of-use">Terms of Use</Link></div>
      <div><Link to="tos/privacy-policy">Privacy Policy</Link></div>
    </div>
    <div style={{textAlign: 'center'}}>
      <div>info@efef.fun</div>
      <div>Km 15 + 990 via las Palmas</div>
      <div>Envigado - Colombia</div>
    </div>
    <div style={{fontSize: 10,textAlign: 'center', marginTop: 32}}>
      <div>Copyright 2024 fezuone s.a.s</div>
    </div>
  </section>
</div>
  );
};

export default LandingPage;
