import React from 'react';
import { isNumeric } from '../../helpers/';
import { TEase, tween as doTween } from '../../helpers/engine';
import { ICurrencyDTO, ITween } from '../../interfaces';
import { CurrencyText } from '../currency_text/curency_text';


interface IProps {
  currency?: ICurrencyDTO;
  initialNumber?: number;
  startFromZero: boolean;
  snapIfValueIsLower?: boolean;
  text?: string; 
  className?: string; 
  tweenTime?: number;
  isCurrency?: boolean;
  convertCredits?: boolean;
  finalNumber: number;
  easing?: TEase;
}
interface IState {
  n: number;
  tween?: ITween;
}
export class AnimText extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      n: props.initialNumber ?? 0,
    };
  }
  public componentDidMount() {
    if (isNumeric(this.props.text)) {
      this.play(Number(this.props.text));
    } else if (this.props.finalNumber !== 0) {
      this.play(this.props.finalNumber);
    }
  }
  public componentDidUpdate(prevProps: IProps) {
    if (this.props.snapIfValueIsLower && prevProps.finalNumber > this.props.finalNumber) { 
      return this.snapToValue(this.props.finalNumber); 
    }    
    if (prevProps?.finalNumber !== this.props.finalNumber) {
      this.play(this.props.finalNumber);
    }
  }
  public snapToValue(finalNumber: number) {
    this.state.tween?.stop?.();
    this.setState({n: finalNumber});
  }
  private play(finalNumber: number) {
    this.state.tween?.stop?.();
    this.setState({tween: undefined}, () => {
      const obj = { n: this.props.startFromZero ? 0 : this.state.n };
      const t = doTween({target: obj, duration: this.props.tweenTime || 1000, props: {n: finalNumber}, onUpdate: () => {
        this.setState({n: obj.n});
      }, ease: this.props.easing || 'power4.easeout' });
      this.setState({tween: t})
    });
  }
  public render() {
    return (<>
      <div className={`noselect ${this.props.className || ''}`}>
        <CurrencyText credits={this.state.n} convertCredits={this.props.convertCredits} currency={this.props.currency} />
      </div>
    </>);
  }
}
